import React from 'react';
import spicy from '../../images/chili.svg';

export default function MenuBoardSection({ title, subtitle, color, items }) {
	return (
		<div className={`menuboard-section`}>
			<h2 className={`menuboard-section-title color-${color}`}>
				{title}
			</h2>
			{subtitle && (
				<div className="menuboard-section-subtitle">{subtitle}</div>
			)}

			{items.map(item => (
				<div className="menuboard-item">
					<div className="menuboard-item-left">
						<div className="menuboard-item-title">
							{item.title}
							{item.spicy && (
								<>
									{' '}
									<img className="spicy" src={spicy} />
								</>
							)}
						</div>
						{item.subtitle && (
							<div className="menuboard-item-subtitle">
								{item.subtitle}
							</div>
						)}
					</div>
					{item.price && (
						<div className="menuboard-item-price">{item.price}</div>
					)}
				</div>
			))}
		</div>
	);
}
