import React from 'react';
import { Helmet } from 'react-helmet';
import '../styles/layout-menuboard.css';

export default function LayoutMenuBoard({ children }) {
	return (
		<>
			<Helmet title="When Pigs Fly Menuboard">
				<html lang="en" className="menuboard-noscroll" />

				<meta http-equiv="refresh" content="3600" />

				<body className="menuboard-layout menuboard-noscroll" />
			</Helmet>
			{children}
		</>
	);
}
