import React from 'react';
import MenuBoard from '../../../components/MenuBoard';
import MenuBoardSection from '../../../components/MenuBoardSection';
import LayoutMenuBoard from '../../../layouts/layout-menuboard';
import { HAPPY_HOUR, MORE_STUFF, SALADS } from './../../../utils/items';

export default function MenuBoard4() {
	return (
		<LayoutMenuBoard>
			<MenuBoard>
				<MenuBoardSection {...MORE_STUFF} />
				<MenuBoardSection {...HAPPY_HOUR} />
				<MenuBoardSection {...SALADS} />
			</MenuBoard>
		</LayoutMenuBoard>
	);
}
