import React from 'react';

export const GOOD_STUFF = {
	title: 'the good stuff',
	color: 'blue',
	items: [
		{
			title: 'the shack (½lb)',
			subtitle: '1 meat, 2 sides + a hawaiian roll or corn muffin',
			price: 13
		},
		{
			title: 'the shed (1 lb)',
			subtitle: '2 meats, 2 sides + a hawaiian roll or corn muffin',
			price: 15
		},
		{
			title: 'the barnyard (1 ½ lb)',
			subtitle: '3 meats, 2 sides + a hawaiian roll or corn muffin',
			price: 17
		},
		{
			title: 'the boneyard',
			subtitle: (
				<div style={{ width: '100%' }}>
					slab of pork or beef ribs, 2 sides, <br />+ a hawaiian roll
					or corn muffin
				</div>
			),
			price: (
				<div>
					<span className="menuboard-item-subtitle">half slab</span>{' '}
					24
					<br />
					<span className="menuboard-item-subtitle">
						full slab
					</span>{' '}
					32
				</div>
			)
		}
	]
};

export const ALACARTE = {
	title: 'À la carte',
	color: 'green',
	items: [
		{
			title: 'bbq sandwich',
			subtitle: 'choice of meat with white bun. add cole slaw +$1',
			price: 8
		},
		{
			title: "shrimp po' boy",
			subtitle: 'popcorn shrimp, cole slaw with spicy mayo drizzle',
			price: 11
		},
		{
			title: 'hog dog',
			subtitle: 'house made sausage with pork with cole slaw',
			price: 11,
			spicy: true
		},
		{ title: '½ slab ribs', price: 19 },
		{ title: 'full slab ribs', price: 28 },
		{ title: 'small side (6oz)', price: 3 },
		{ title: 'large side (16oz)', price: 8 },
		{ title: '4 side combo', price: 10 }
	]
};

export const BURGERS = {
	title: 'Burgers & Dogs',
	color: 'yellow',
	subtitle: (
		<>
			served with hand-cut, seasoned dipstick fries
			<br />
			add grilled onions, bacon, fried egg, or avocado + $1.50
		</>
	),
	items: [
		{
			title: 'big block burger',
			subtitle:
				"½lb smoked Brandt Beef cheeseburger with all the fixin's",
			price: 12
		},
		{
			title: 'hubcap burger',
			subtitle:
				"½lb smoked Brandt Beef burger, potato bun, a crispy cheese wheel, and all the fixin's",
			price: 15
		},
		{
			title: 'toolbox burger',
			subtitle:
				'½lb smoked Brandt Beef burger, potato bun, pulled pork, sweet pickles, housemade onion rings, BBQ sauce',
			price: 15
		},
		{
			title: 'brisket & bleu burger',
			subtitle:
				'½lb smoked Brandt Beef burger, smoked brisket,housemade blue cheese dressing',
			price: 15
		},
		{
			title: 'small block burger',
			subtitle:
				"¼lb smoked Brandt Beef cheeseburger with all the fixin's",
			price: 8
		},
		{
			title: 'hot rod',
			subtitle:
				'premium 100% nitrate-free and all natural brandt beef hot dog, spicy mayo, dijon mustard, sweet relish',
			price: 7
		},
		{
			title: 'outlaw hot rod',
			subtitle:
				'premium 100% nitrate-free and all natural brandt beef hot dog, outlawbrisket chili, sour cream, green onion',
			price: 9
		},
		{
			title: 'dipsticks',
			subtitle: 'hand-cut steak fries',
			price: 5
		}
	]
};

export const MORE_STUFF = {
	title: 'More stuff',
	subtitle: 'includes 2 sides + hawaiian roll or corn muffin',
	items: [
		{ title: 'bbq sandwich plate', price: 13 },
		{
			title: "shrimp po' boy plate",
			subtitle: 'popcorn shrimp, cole slaw with spicy mayo drizzle',
			price: 15
		},
		{
			title: 'hog dog plate',
			subtitle: 'house made sausage with pork with cole slaw',
			price: 13,
			spicy: true
		}
	]
};

export const KIDS = {
	title: 'Kids',
	subtitle: (
		<>
			(kids 10 and under, please)
			<br />
			includes 1 side dish, juice box, and fruit snack
		</>
	),
	items: [
		{
			title: 'small block burger',
			price: 8
		},
		{
			title: 'chicken nuggets',
			price: 6
		},
		{
			title: 'grilled cheese sandwich',
			price: 6
		},
		{
			title: 'bbq sandwich',
			price: 7
		},
		{
			title: 'hot dog',
			price: 6
		},
		{
			title: 'mac & cheese',
			price: 6
		}
	]
};

export const WEEKEND = {
	title: 'friday & saturday',
	items: [
		{
			title: '12oz ribeye plate',
			subtitle:
				'hand-selected ribeye + loaded baked potato, a side and cornbread muffin',
			price: 34
		},
		{
			title: '12oz prime rib plate',
			subtitle: '+ loaded baked potato, a side and a cornbread muffin',
			price: 36
		}
	]
};

export const SALADS = {
	title: 'Salads',
	subtitle: 'add a meat of your choice + $3',
	color: 'green',
	items: [
		{
			title: 'caesar salad',
			price: 10
		},
		{
			title: 'loaded green salad',
			price: 12
		}
	]
};

export const HAPPY_HOUR = {
	title: 'Happy Hour',
	subtitle: 'served all day, every day',
	color: 'blue',
	items: [
		{
			title: 'onion rings',
			price: 8
		},
		{
			title: 'wings',
			price: 6
		},
		{
			title: 'riblets',
			price: 6
		},
		{
			title: 'pig candy',
			price: 6,
			spicy: true
		},
		{
			title: 'deviled eggs',
			price: 6
		},
		{
			title: 'bbq nachos',
			subtitle: 'with pulled pork + all the fixins',
			price: 8
		},
		{
			title: 'brisket outlaw chili',
			subtitle: 'spicy chili + housemade corn muffin',
			price: 8,
			spicy: true
		},
		{
			title: 'redneck tacos',
			subtitle: '3 tacos with pulled pork + crunchy coleslaw',
			price: 8
		}
	]
};

export const DRINKS = {
	title: 'Drinks',
	color: 'green',
	items: [
		{
			title: 'soda',
			price: 3
		},
		{
			title: 'sweet tea',
			price: 3
		},
		{
			title: 'coffee',
			price: 3
		},
		{
			title: 'beer on tap',
			price: '5+'
		}
	]
};

export const DESSERTS = {
	title: 'Desserts',
	color: 'yellow',
	items: [
		{
			title: 'smoked peach cobbler',
			subtitle: 'with vanilla bean ice cream + $2',
			price: 7
		},
		{
			title: 'berry cobbler',
			subtitle: 'with ice cream',
			price: 9
		},
		{
			title: 'brownie sundae',
			price: 9
		},
		{
			title: 'caramel bread pudding',
			subtitle: 'with vanilla bean ice cream',
			price: 9
		},
		{
			title: 'freshly-baked cookies',
			subtitle: 'chocolate chip or peanut butter',
			price: 4
		}
		// {
		// 	title: 'smoked maple bourbon praline pecan ice cream',
		// 	price: 4
		// }
	]
};
